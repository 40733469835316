<template>

  <v-container
  height="100%"
  width="300"
  class="background-wallpaper-login"
  fluid fill-height
  >

  <v-navigation-drawer v-if="$vuetify.breakpoint.lgAndUp" clipped app class="left-panel" width="600">
    
    <!-- Display Logo Plus -->
    <v-list-item>
      <v-list-item-content class="ma-12">
        <!-- Back to Login -->
        <v-row class="ml-5">
            <v-col>
              <v-icon density="compact" @click="backToLogin()" color="primary">mdi-arrow-left</v-icon>
            </v-col>
        </v-row>
        <v-list-item-title justify="center" align="center">
          

          <v-img
          src="@/assets/img/logo/logo.png"
          width="180"
          ></v-img>

          <h3 class="pa-10 pb-0 primary--text">For External User</h3>

          <!-- <v-row>
            <v-col pa-12 pb-5 >
              <v-subheader>Username</v-subheader>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col class="pa-12 pb-5">
              <v-text-field
              v-model="modelLoginForm.username"
              outlined
              prepend-inner-icon="mdi-account"
              label="Username"
              hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-12 pt-0 pb-5">
              <v-text-field
              v-model="modelLoginForm.password"
              outlined
              prepend-inner-icon=""
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'test' : 'password'"
              @click:append="show1 = !show1"
              label="Password"
              hide-details
              autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-12 pt-0 pb-5" justify="center" align="center">
              <v-checkbox
              label="Remember me"
              hide-details
              v-model="modelLoginForm.checkboxRememberMe"
              @click="rememberMe()"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-5">
              <v-btn
              width="400px"
              rounded
              large
              class="pt-0"
              elevation="0"
              color= primary
              @click="externalLogin()"
              >
              <span class="white--text">SIGN IN</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <span d-flex class="small-btnn" @click="forgotPassword()">
                Forgot Password?
              </span>
            </v-col>
          </v-row>
          
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

  </v-navigation-drawer>
  
  <v-row v-if="!$vuetify.breakpoint.lgAndUp">

    

    <v-col align="center" justify="center">
      <v-card class="pa-5" align="center" justify="center" width="500px">

        <v-row class="ml-5">
          <v-col>
            <v-icon density="compact" @click="backToLogin()" color="primary">mdi-arrow-left</v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-img
            src="@/assets/img/logo/logo.png"
            width="180"
            ></v-img>
             
             
            <h3 class="pa-10 pb-0 primary--text">For External User</h3>
          </v-col>
        </v-row>
          
        <v-row>
          <v-col class="pa-12 pb-5">
            <v-text-field
            v-model="modelLoginForm.username"
            outlined
            prepend-inner-icon="mdi-account"
            label="Username"
            hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
            <v-col class="pa-12 pt-0 pb-5">
              <v-text-field
              v-model="modelLoginForm.password"
              outlined
              prepend-inner-icon=""
              append-icon="mdi-eye-off"
              label="Password"
              hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-12 pt-0 pb-5" justify="center" align="center">
              <v-checkbox
              label="Remember me"
              hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-5">
              <v-btn
              width="400px"
              rounded
              large
              class="external-login-button pt-0"
              elevation="0"
              color= primary
              @click="externalLogin()"
              >
              <span class="white--text">SIGN IN</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <span d-flex class="text-caption small-btnn" @click="forgotPassword()">
                Forgot Password?
              </span>
            </v-col>
          </v-row>

      </v-card>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>
// -- import something here --

export default {

  data: () => ({

    show1:false,

    modelLoginForm: {
      
      username: "",
      password: "",
      checkboxRememberMe:"",
      mode: "EXTERNAL",

    }
    
  }),

  mounted() {

    this.checkLoginRememberMe();
    // console.log(JSON.parse(localStorage.RememberMe));
    
  },

  methods: {

    checkLoginRememberMe()
    {
      if(localStorage.RememberMe != undefined)
      {
        // console.log("Hello");
        let tempLocalStorage = JSON.parse(localStorage.RememberMe)

        // console.log(tempLocalStorage);

        if (tempLocalStorage.checkboxRememberMe == true)
        {
          // console.log("Hello Eqin");
          this.modelLoginForm.username = tempLocalStorage.username;
          this.modelLoginForm.password = tempLocalStorage.password;
          this.modelLoginForm.checkboxRememberMe = tempLocalStorage.checkboxRememberMe;
        }
        else
        {
          this.modelLoginForm.username = "";
          this.modelLoginForm.password = "";
          this.modelLoginForm.checkboxRememberMe = "";
        }
      }
    },

    loginTo(to){
        // console.log(to);
      },

    goToLogin(data)
    {
      // console.log(data);
      if(data == "Plus User")
      {
        // console.log(data);

        if(location.protocol=='http:'){ 
          window.location.href ="https://login.microsoftonline.com/4b4a4c51-ed99-465f-96cd-1a5bc81a8d60/oauth2/authorize?response_type=id_token&client_id=01e1005b-a4ec-4b9f-804d-bc77f187631f&redirect_uri=https://temanrf.plus.com.my/plus/&nonce=asdasdasdas";
        }
        else
        {
          window.location.href ="https://login.microsoftonline.com/4b4a4c51-ed99-465f-96cd-1a5bc81a8d60/oauth2/authorize?response_type=id_token&client_id=01e1005b-a4ec-4b9f-804d-bc77f187631f&redirect_uri=https://teman.plus.com.my/plus/&nonce=asdasdasdas";
        }

        // window.location.href ="https://login.microsoftonline.com/240e4cb4-e57a-48ad-bc81-b4298c49a8e8/oauth2/authorize?response_type=id_token&client_id=e6c427b8-8564-4f1b-ad99-41eb84790b7f&redirect_uri=https%3A%2F%2Fdonutgebu.shaz.my%2Fplus%2Flandingpage.php&state=05518293-224b-49f9-84f1-e91ba45a4cf1&client-request-id=2a2abd6a-7b48-43cc-b9d9-0af9f4504db8&x-client-SKU=Js&x-client-Ver=1.0.11&nonce=51ca059c-f993-4e4d-8b5b-354a3d4159e8"
     
     }

      else if(data == "External User")
      {
        // console.log(data);
        this.goToPage("/ExternalLogin")
      }
    },

    backToLogin() 
    {
      this.goToPage("/")
    },

    externalLogin()
    {
      // console.log(this.$store);
      this.$store.dispatch("signUserIn", this.modelLoginForm)
    },

    rememberMe()
    {
      // console.log(this.modelLoginForm);
    },

    forgotPassword()
    {
      // console.log("HELLO");
      this.goToPage("/ForgotPassword")
    },
    
  },

};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

.left-panel
{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.background-wallpaper-login
{
  background-image: url("~@/assets/img/wallpaper/bgg.jpg") !important;
  background-size:cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

// .v-list-item 
// {
//   min-height: 100%;
// }

.external-login-button{
  cursor: pointer;
  background: rgb(9,86,164);
}

.external-login-button:hover{
  cursor: pointer;
  background: rgb(4,153,75);
}
.small-btnn{
    cursor: pointer;
}

.small-btnn:hover{
    cursor: pointer;
    color: $primary;
}

</style>


